// import hero from './hero.png';
import hero from './Hero2.png';

const Banner = () => {
  return (
 <>
      
        <div className="hero">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-6 mt-5">
                        <div className="hero-text">
                            <h1>Unleash the Might of Innovation!</h1>

                            <p>
                                Armed with cutting-edge technology, we are the fearless warriors of progress, battling on the frontlines of innovation. In the vast battlefield of business, we wield our tech-swords to slash through challenges and emerge victorious.
                            </p>
                            <div className="hero-btn">
                                <a className="btn" href="/contact">Summon the Council of Tech-Warriors: Inquire Now!</a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 d-none d-md-block">
                        <div className="hero-image">
                            <img src={hero} alt="Hero Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


   </>
  
  );
};

export default Banner;
