


const Footer = () => {
  return (
    <>
  <div className="footer wow fadeIn" data-wow-delay="0.3s">
            <div className="container-fluid">
                <div className="container">
                    <div className="footer-info">
                        <a href="/" className="footer-logo"><span className="FLogo">Thew</span> Tech</a>
                        <h3>26/19, Bharathidhasan Street, P.N Pudur, Coimbatore</h3>
                        <div className="footer-menu">
                            <p>+91 9025388166</p>
                            <p>+91 7418066768</p>
                            <p>+91 9360224118</p>
                        </div>
                        <div className="footer-menu">
                            
                            <p>info@thewtech.in</p>
                        </div>
                        <div className="footer-social">
                            <a href="https://www.facebook.com/profile.php?id=100095552109237" target="_blank"><i className="fab fa-facebook-f"></i></a>                          
                            <a href="https://www.linkedin.com/company/thewtech/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-12">
                            <p>&copy; <a href="/">ThewTech</a>, All Rights Reserved.</p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
  </>
  );
};

export default Footer;
