import Layer from '../Layer/Layer.js';
import offer from './offer.jpg';
import application from './health.png';
import web from './h2.jpg';
import ml from './h3.png';
import mobile from './h5.png';
import erp from './h6.png';
import crm from './h7.png';
import pay from './h4.jpg';
import ecom from './h8.jpg';
import usercent from './usercenterd.jpg';
import scale from './scalable.png';
import reliable from './Why-Managed-Services.png';
import React, { useState,useEffect } from 'react';
import $ from 'jquery';
import owlCarousel from 'react-owl-carousel';
import ecommerce from './ecommerce_1.jpg';
import bank from './bank.jpg';
import education from './education.jpg';
import food from './food.jpg';
import health from './health.png';
import travel from './travel.jpg';
import media from './media.png';
import realestate from './relestate.jpg';
import more from './more.jpg';

const Services = () => {
     const [selectedSlider, setSelectedSlider] = useState('ecom');

  const handleRadioClick = (value) => {
    setSelectedSlider(value);
  };
    useEffect(() => {
  // componentDidMount() {}
  const externalScript = document.createElement('script');
  externalScript.src = './assets/lib/owlcarousel/owl.carousel.min.js';
  externalScript.async = true;

  const externalScript2 = document.createElement('script');
  externalScript2.src = './assets/js/main.js';
  externalScript2.async = true;
  document.body.append(externalScript2);

  // const inlineScript = document.createElement('script');
  // inlineScript.innerHTML = '...';
  // document.body.append(inlineScript);


  // componentWillUnmount() {}
  return () => {
    externalScript.remove();
    externalScript2.remove();
    // inlineScript.remove();
  };
}, []);


function generateLyrics() {
  const lyrics = [
    "Now they know how many holes it takes to fill the Albert Hall.",
    "Yesterday. All my troubles seemed so far away.",
    "I woke up. Fell out of bed. Dragged a comb across my head.",
    "I've just seen a face. I can't forget the time or place."
  ];
  const randomNumber = Math.floor(Math.random() * 4);
  return lyrics[randomNumber];
}

/**
 * Add the event listeners for
 * each thumbnail image. When
 * there's a click, generate
 * a random Beatles lyrics and
 * display them in the text
 * content area.
 */

const imgThumbs = document.querySelectorAll("img");

// Iterator
imgThumbs.forEach((elt) => {
  elt.addEventListener("click", (evt) => {
    console.log("Image clicked.");
    document
      .querySelector(".table-content")
      .innerText = generateLyrics();
  });
});

  return (
 <>        
  <Layer/> 
<div className="testimonial wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="section-header text-center">
                <p>Our Services - Unleashing Techno-Warriors!</p>
                  <h2>What we offer?</h2>
                </div>
    
<div className="row">
<div className="col-lg-12">
<div className="single-content wow fadeInUp">


<p style={{textAlign: 'center'}}>
Embark on a journey with our legion of tech warriors, armed with digital weaponry that transcends the ordinary. Our team of battle-hardened professionals is not just here to provide services; we're on a mission to wield the forces of technology for real impact.
</p>
</div>
</div>
</div>

                <div className="owl-carousel testimonials-carousel">
                    <div className="testimonial-item">
                        <div className="testimonial-img">
                              
<i className="fas fa-globe"></i>

                        </div>
                        <div className="testimonial-text">
                            <p>
                                In the vast battlefield of connectivity, our warriors brandish the Sword of Seamless Connectivity, cutting through digital barriers to unite businesses with their audiences effortlessly.
                            </p>
                            <h3>Web Application - The Sword of Seamless Connectivity</h3>
                            
                        </div>
                    </div>
                    <div className="testimonial-item">
                        <div className="testimonial-img">
<i className="battleicon fas fa-mobile-alt"></i>
                        </div>
                        <div className="testimonial-text">
                            <p>
                                Armored with the Shield of Accessibility, our mobile warriors pave the way for businesses to be reachable anywhere, anytime. Defending your digital realm against obscurity.
                            </p>
                            <h3>Mobile Application - The Shield of Accessibility </h3>
                            
                        </div>
                    </div>
                    <div className="testimonial-item">
    <div className="testimonial-img">
  <i class="fas fa-blog"></i>
    </div>
    <div className="testimonial-text">
        <p>
            Wearing the Helmet of Digital Presence, our web warriors stand tall, crafting websites that not only exist but radiate a commanding digital presence, leaving a lasting mark.
        </p>
        <h3>Website - The Helmet of Digital Presence</h3>
    </div>
</div>

<div className="testimonial-item">
    <div className="testimonial-img">
<i class="fas fa-shopping-cart"></i>
    </div>
    <div className="testimonial-text">
        <p>
            The Bow of Business Expansion is drawn by our e-commerce archers, propelling your business arrows to new heights, conquering markets with precision and flair.
        </p>
        <h3>E-Commerce - The Bow of Business Expansion</h3>
    </div>
</div>

<div className="testimonial-item">
    <div className="testimonial-img">

<i class="fas fa-search-dollar"></i>
    </div>
    <div className="testimonial-text">
        <p>
            In the mystical realm of data, our Machine Learning wizards conjure the Mystic Orb of Intelligent Insights, unraveling patterns and foreseeing the future for strategic dominance.
        </p>
        <h3>Machine Learning - The Mystic Orb of Intelligent Insights</h3>
    </div>
</div>

<div className="testimonial-item">
    <div className="testimonial-img">
<i class="fas fa-handshake"></i>
    </div>
    <div className="testimonial-text">
        <p>
            Clad in the Gauntlet of Customer Relations, our CRM champions forge alliances, ensuring every client interaction is a victorious encounter, building bonds that withstand the test of time.
        </p>
        <h3>CRM - The Gauntlet of Customer Relations</h3>
    </div>
</div>

<div className="testimonial-item">
    <div className="testimonial-img">
     <i class="fas fa-building"></i>
    </div>
    <div className="testimonial-text">
        <p>
            ERP, the Enchanted Scroll of Enterprise Management, is wielded by our tactical strategists, scripting the saga of efficient operations and organizational wizardry.
        </p>
        <h3>ERP - The Enchanted Scroll of Enterprise Management</h3>
    </div>
</div>

<div className="testimonial-item">
    <div className="testimonial-img">
<i class="fas fa-hand-holding-usd"></i>
    </div>
    <div className="testimonial-text">
        <p>
            Behold the Ledger of Financial Harmony, our financial warriors managing payroll with precision, ensuring financial realms are in perfect order.
        </p>
        <h3>Payroll - The Ledger of Financial Harmony</h3>
    </div>
</div>

               
                </div>
            </div>
        </div>


 <div className="team">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    
                    <h2>Industries We Conquer - Where Innovation Meets Battle!</h2>
                </div>
                   

<div className="row">
<div className="col-lg-12">
<div className="single-content wow fadeInUp">


<p style={{textAlign: 'center'}}>
In our conquest through the digital realms, we wield the technological weaponry of innovation like fearless warriors. We don't just serve industries; we lead them into the future, armed with solutions tailored to their needs. Witness our impact across a multitude of sectors are as follows.
</p>
</div>
</div>
</div>


               
                   
               
            </div>
        </div>

{/*--------------------------------------------------*/}

<div className="containerlo">

        <div className="topic">

 
  <strong>Click the buttons below to explore more about our works</strong>


        </div>

        <div className="content">
      
      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'ecom'}
        id="ecom"
        onChange={() => handleRadioClick('ecom')}
      />
     

       <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'health'}
        id="health"
        onChange={() => handleRadioClick('health')}
      />


      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'fin'}
        id="fin"
        onChange={() => handleRadioClick('fin')}
      />
  

      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'edu'}
        id="edu"
        onChange={() => handleRadioClick('edu')}
      />


      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'entertain'}
        id="entertain"
        onChange={() => handleRadioClick('entertain')}
      />
    

      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'travel'}
        id="travel"
        onChange={() => handleRadioClick('travel')}
      />
     

      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'real'}
        id="real"
        onChange={() => handleRadioClick('real')}
      />


      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'food'}
        id="food"
        onChange={() => handleRadioClick('food')}
      />
     

      <input
        type="radio"
        name="slider"
        checked={selectedSlider === 'more'}
        id="more"
        onChange={() => handleRadioClick('more')}
      />
      
   
    

            <div className="list">
                <label for="ecom" className="ecom">
                    <span>Ecommerce and Retail</span>
                </label>
                <label for="health" className="health">
                    <span>Health Care</span>
                </label>
                <label for="fin" className="fin">
                    <span>Finance and Banking</span>
                </label>
                <label for="edu" className="edu">
                    <span>Education</span>
                </label>
                <label for="entertain" className="entertain">
                    <span>Entertainment and Media</span>
                </label>
               
                <label for="travel" className="travel">
                    <span>Travel and Hospitality</span>
                </label>
                <label for="real" className="real">
                    <span>Real Estate</span>
                </label>
                <label for="food" className="food">
                    <span>Food and Beverage</span>
                </label>
                 <label for="more" className="more">
                    <span>And More!</span>
                </label>
                <div className="slider"></div>
            </div>

            <div className="text-content">
                <div className="ecom text">
                    <div className="title">Ecommerce and Retail</div>
                  
    <ul class="home-list">
        <li><strong>Smart Shopping Algorithms: </strong>Implementing machine learning to analyze user behavior and offer personalized product recommendations, enhancing the shopping experience.</li>
        <li><strong>Virtual Reality Product Selection: </strong> Collaborating with animation teams to create game-oriented virtual reality interfaces, allowing users to virtually explore and choose products.</li>
        <li><strong>Seamless Ecommerce Platforms: </strong> Developing user-friendly and efficient ecommerce websites for leading retail brands, ensuring smooth transactions and optimal customer satisfaction.</li>
        <li><strong>Inventory Management Solutions: </strong> Deploying advanced technology to streamline inventory processes, reducing errors and optimizing supply chain management.</li>
        <li> <strong>Automated Customer Support: </strong> Introducing AI-powered chatbots for instant customer assistance, providing real-time support and enhancing overall customer engagement.</li>
    </ul>
                </div>
                <div className="health text">
                        <div class="title">Health Care</div>
    <ul class="home-list">
        <li><strong>Biometric Attendance System: </strong>Implementing biometric technology for accurate attendance tracking, ensuring a secure and efficient workforce management system.</li>
        <li><strong>Mobile Healthcare Applications: </strong>Developing mobile applications for healthcare professionals and patients, facilitating appointment scheduling, medication reminders, and remote consultations.</li>
        <li><strong>Telemedicine Solutions: </strong>Integrating technology to enable virtual healthcare services, connecting patients with healthcare providers for remote consultations.</li>
        <li><strong>Electronic Health Records (EHR): </strong>Implementing secure and efficient EHR systems for digital storage and management of patient information.</li>
        <li><strong>Health Monitoring Wearables: </strong>Utilizing wearable technology to track and monitor patients' health data, providing valuable insights for proactive healthcare management.</li>
    </ul>

                </div>
                <div className="fin text">
                    <div class="title">Finance and Banking</div>
<ul class="home-list">
    <li><strong>Advanced Payroll Systems: </strong>Developing comprehensive payroll solutions with visualization charts for financial institutions, ensuring accuracy and compliance.</li>
    <li><strong>Fraud Detection Technologies: </strong>Implementing AI-driven algorithms to detect and prevent fraudulent activities, enhancing the security of financial transactions.</li>
    <li><strong>Mobile Banking Applications: </strong>Creating user-friendly mobile applications for seamless banking transactions and account management.</li>
    <li><strong>Blockchain Integration: </strong>Exploring blockchain technology for secure and transparent financial transactions and data management.</li>
    <li><strong>Personalized Financial Planning: </strong>Utilizing machine learning to analyze customer financial data and offer personalized financial planning advice.</li>
</ul>
                </div>
                <div className="edu text">
                    <div class="title">Education</div>
<ul class="home-list">
    <li><strong>Learning Management Systems (LMS): </strong>Developing robust LMS platforms for educational institutions, facilitating online learning and collaboration.</li>
    <li><strong>Interactive Educational Content: </strong>Collaborating with animation teams to create interactive and engaging educational content, enhancing the learning experience.</li>
    <li><strong>Virtual Classroom Solutions: </strong>Introducing virtual classroom technologies for remote learning, connecting educators and students worldwide.</li>
    <li><strong>Gamified Learning Platforms: </strong>Incorporating game-oriented elements into educational platforms to increase student engagement and motivation.</li>
    <li><strong>Data Analytics for Academic Improvement: </strong>Utilizing data analytics to assess student performance, providing insights for targeted academic interventions.</li>
</ul>
</div>
                <div className="entertain text">
                    <div class="title">Entertainment and Media</div>
<ul class="home-list">
    <li><strong>Immersive Content Experiences: </strong>Collaborating with animation specialists to create immersive and visually stunning content experiences for entertainment platforms.</li>
    <li><strong>Augmented Reality (AR) Integration: </strong>Exploring AR technology to enhance user interaction with media content, creating unique and memorable experiences.</li>
    <li><strong>Content Personalization Algorithms: </strong>Implementing machine learning to analyze user preferences and tailor content recommendations, maximizing viewer engagement.</li>
    <li><strong>Streaming Technology Optimization: </strong>Enhancing streaming platforms with advanced technologies to ensure seamless playback and high-quality user experiences.</li>
    <li><strong>Digital Rights Management (DRM): </strong>Implementing robust DRM solutions to protect intellectual property and prevent unauthorized distribution of media content.</li>
</ul>
  </div>
                 <div className="travel text">
                    <div class="title">Travel and Hospitality</div>
<ul class="home-list">
    <li><strong>User-Friendly Booking Platforms: </strong>Developing intuitive websites and applications for easy and efficient taxi bookings and hotel reservations.</li>
    <li><strong>Dynamic Pricing Algorithms: </strong>Implementing dynamic pricing strategies based on demand and supply data, optimizing revenue for hotels and travel services.</li>
    <li><strong>Augmented Reality Travel Guides: </strong>Exploring AR technology to provide travelers with interactive and informative guides for tourist destinations.</li>
    <li><strong>Customer Loyalty Programs: </strong>Designing and implementing digital loyalty programs to enhance customer retention for travel and hospitality businesses.</li>
    <li><strong>Blockchain for Secure Transactions: </strong>Exploring blockchain technology to ensure secure and transparent transactions in the travel and hospitality industry.</li>
</ul>
  </div>
                 <div className="real text">
                   <div class="title">Real Estate</div>
<ul class="home-list">
    <li><strong>Virtual Property Tours: </strong>Creating immersive virtual tours for real estate listings, allowing potential buyers to explore properties remotely.</li>
    <li><strong>Smart Property Management Systems: </strong>Implementing IoT devices and automation for efficient property management, including security and energy control.</li>
    <li><strong>Blockchain for Property Transactions: </strong>Exploring blockchain technology for transparent and secure real estate transactions, reducing fraud and improving trust.</li>
    <li><strong>Predictive Analytics for Property Values: </strong>Utilizing data analytics to predict property value trends, assisting buyers and sellers in making informed decisions.</li>
    <li><strong>Paperless Documentation Processes: </strong>Implementing digital solutions for document management, reducing paperwork and streamlining real estate transactions.</li>
</ul>
  </div>
                 <div className="food text">
                    <div class="title">Food and Beverage</div>
<ul class="home-list">
    <li><strong>Online Ordering Platforms: </strong>Developing user-friendly online platforms for food ordering and delivery services, ensuring a seamless customer experience.</li>
    <li><strong>Kitchen Automation: </strong>Implementing technology to automate kitchen processes, improving efficiency and reducing wait times for food orders.</li>
    <li><strong>Customer Loyalty Apps: </strong>Creating digital loyalty programs and mobile applications to enhance customer retention for restaurants and food establishments.</li>
    <li><strong>Inventory Management Solutions: </strong>Streamlining inventory processes with technology to prevent stockouts and minimize food wastage.</li>
    <li><strong>Contactless Payment Systems: </strong>Introducing contactless payment options and digital wallets for convenient and secure transactions in food and beverage establishments.</li>
</ul>
   </div>
                 <div className="more text">
                    <div class="title">And More!</div>
<ul class="home-list">
    <li><strong>Client-Centric Innovation: </strong>Tailoring solutions for various industries with a focus on client needs and preferences.</li>
    <li><strong>Continuous Technological Exploration: </strong>Staying at the forefront of technological advancements to bring excellence to diverse sectors.</li>
    <li><strong>Cross-Industry Collaboration: </strong>Facilitating collaboration between different industries to share technological expertise and foster innovation.</li>
    <li><strong>Global Reach and Impact: </strong>Extending technological solutions globally to make a significant impact across various markets.</li>
    <li><strong>Versatile Technological Expertise: </strong>Providing a range of services that cater to the unique technological requirements of each industry.</li>
</ul>
    </div>
            </div>
        </div>
    </div>
{/*--------------------------------------------------*/}
 <div className="service">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                   <p>Unlock Your Potential with Our Student-Centric Offerings</p>
                    <h2>Empowering Tomorrow's Innovators</h2>
                   
                </div>
                 <p style={{textAlign: 'center'}}>In the epic saga of digital transformation, the choice of a web application development ally is your ultimate quest. Amidst the chaos of the business battleground, we emerge as the valiant warriors, armed with a distinctive blend of qualities that make us the champions for your journey. Behold the reasons to march with us:</p>
                <div className="row">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                        <div className="service-item">
                            <div className="service-icon">
                                
                                <i class="battleicon fas fa-signal"></i>

                            </div>
                            <h3>Online Internships for Students</h3>
                            <p>
                                Explore our online internship programs designed to provide students with real-world experience and practical skills. Engage in meaningful projects, gain industry insights, and kickstart your career journey from the comfort of your home.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="service-item">
                            <div className="service-icon">
                              <i class="battleicon  fas fa-graduation-cap"></i>

                            </div>
                            <h3>College Projects that Ignite Creativity</h3>
                            <p>
                                Unleash your creative potential with our college project initiatives. From ideation to execution, we guide students through hands-on projects that not only fulfill academic requirements but also spark innovation and ignite a passion for technology.
                            </p>
                        </div>
                    </div>
                 
                  
                  
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1s">
                        <div className="service-item">
                            <div className="service-icon">
                               
                               <i class="battleicon fas fa-university"></i>
                            </div>
                            <h3>Weekend Workshops Onsite at Your College</h3>
                            <p>
                                Elevate your learning experience with our onsite weekend workshops conducted right at your college. Dive deep into the latest technologies, collaborate with industry experts, and transform theoretical knowledge into practical expertise.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div> 
   </>
  
  );
};

export default Services;
