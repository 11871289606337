
const Layer = () => {
  return (
 <>
      
         <div className="page-header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        
                    </div>
                  
                </div>
            </div>
        </div>  
   </>
  
  );
};

export default Layer;
