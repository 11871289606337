import brain from './pngegg (4).png';
import customer from './pngegg (4).png';
import innovation from './pngegg (4).png';
import quality_1 from './pngegg (4).png';
import time from './pngegg (4).png';
import soln from './pngegg (4).png';


const Why_choose_us = () => {

  return (
 <>
      <div className="star-layers discount wow zoomIn" data-wow-delay="0.1s">

  <div class="star-layer" id="stars"></div>
  <div class="star-layer" id="stars2"></div>
  <div class="star-layer" id="stars3"></div>

            <div className="container">
                <div className="section-header text-center">
                   
                    <h2>Forge the <span>Future</span> with Us! </h2>
                </div>
                <div className="container discount-text">
                    <p>
                        Your triumph fuels our passion, and we stand ready to wield the weapons of technology for your growth and prosperity. Let us seize your vision and mold it into reality. Whether you seek to streamline your operations, enchant customer realms, or revolutionize your industry, fear not – our web applications are the mighty shields in your digital arsenal.
                    </p>
                    <p>Our league of tech-warriors eagerly awaits, poised to turn your ideas into digital reality, sparking a revolution in the landscapes of business. Let's join forces, innovate like never before, and craft a future that shines brightly for your business. Together, we shall charge into the digital revolution and achieve greatness.</p>
                    <p>Summon us for a consultation, and together, let's script the future of your business in the digital epic. We are your steadfast partners in success. Join us, and let's forge something truly extraordinary together!</p>
                    <a className="btn" href="/contact">Choose the warrior's guild with us, and let's script your diverse technological saga together!</a>
                </div>
            </div>
        </div>
        <div className="service">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                   <p>Why us</p>
                    <h2>Why Choose the Warrior's Path with Us</h2>
                   
                </div>
                 <p style={{textAlign: 'center'}}>In the epic saga of digital transformation, the choice of a web application development ally is your ultimate quest. Amidst the chaos of the business battleground, we emerge as the valiant warriors, armed with a distinctive blend of qualities that make us the champions for your journey. Behold the reasons to march with us:</p>
                <div className="row">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                        <div className="service-item">
                            <div className="service-icon">
                                <i className="battleicon fab fa-wolf-pack-battalion"></i>
                            </div>
                            <h3>Battle-Hardened Expertise</h3>
                            <p>
                                Our fellowship boasts seasoned developers, designers, and project commanders, each wielding a wealth of expertise. Together, we forge through the challenges, battle-tested and ready for the digital crusade.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="service-item">
                            <div className="service-icon">
                              <i class="battleicon fab fa-studiovinari"></i>

                            </div>
                            <h3>Trailblazing Innovation</h3>
                            <p>
                                In the ever-shifting landscapes of technology, we are the pioneers. Fueled by the spirit of innovation, we wield cutting-edge technologies as our weapons, leading the charge and reshaping the industry's destiny.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="service-item">
                            <div className="service-icon">
                              <i class="battleicon fab fa-phoenix-framework"></i>

                            </div>
                            <h3>Unyielding Quality Code</h3>
                            <p>
                                Excellence is our battle cry, and quality is our armor. We take pride in crafting products and services that stand as fortresses of reliability. Each line of code is a testament to our commitment to unwavering quality.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item">
                            <div className="service-icon">
                               <i class="battleicon fab fa-phoenix-squadron"></i>

                            </div>
                            <h3>Warrior's Pact: Customer-Centric Ethos</h3>
                            <p>
                                Your triumph, our pledge. In the digital forge, side by side, your strategies echo, your ideas fuel our quest
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                        <div className="service-item">
                            <div className="service-icon">
                               <i class="battleicon fab fa-superpowers"></i>
                            </div>
                            <h3>Swift Dispatch</h3>
                            <p>
                                Time, the currency of the battlefield, is of the essence. Fear not, for our streamlined processes are finely tuned, ensuring the timely delivery of victories without compromise.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1s">
                        <div className="service-item">
                            <div className="service-icon">
                               <i class="battleicon fab fa-wpexplorer"></i>
                            </div>
                            <h3>Total Domination: Comprehensive Solutions</h3>
                            <p>
                                From front lines to digital horizons, our services span the spectrum. We offer end-to-end solutions, a full arsenal for your digital odyssey.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
         
   </>
  
  );
};

export default Why_choose_us;
