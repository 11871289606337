// import hero from './hero.png';
import about from './about2.png';
import about2 from './Ninja_Karate.gif';
import Layer from '../Layer/Layer.js';


const About = () => {
  return (
 <>
      
  <Layer/>    
   
<div className="single oversingle">
<div className="container">
<div className="row">
 
<div className="col-lg-8">
<div className="single-content wow fadeInUp">
<img src={about} />

<h2>About Us - Tech Warriors Unleashed</h2>
<p className="text-justy">
In the vast realm of technology, we stand as valiant warriors, wielding the mighty weapons of innovation and digital prowess. Our journey commenced with a visionary quest to empower businesses and organizations, and today, we proudly stride as leaders in the epic battlefield of web application development.
</p>
<p className="text-justy">
Our formidable team forms the very backbone of our army. A diverse assembly of professionals, our warriors hail from varied backgrounds – seasoned developers, creative designers, sharp thinkers, and vigilant project managers. Together, we forge an alliance, blending a collective pool of skills and expertise that rivals the mightiest forces.
</p>
<p className="text-justy">
Yet, we transcend beyond a mere team; we are a family bound by a shared passion for innovation and an unyielding commitment to excellence. Our work culture echoes with the clang of creativity, transparency, and an unwavering pursuit of improvement. With each project, we embark on a quest to surpass ourselves, pushing the boundaries of what's conceivable in the digital landscape.
</p>

</div>
<div className="single-bio2 wow fadeInUp">
<div className="single-bio-img">
<img src={about2} />
</div>
<div className="single-bio-text">
<div class="textabt">Unveiling the Battle Plan</div>
</div></div>
<div className="single-bio wow fadeInUp">

<div className="single-bio-text">
<div className="glowing">
      <span style={{'--i': 1}}></span>
      <span style={{'--i': 2}}></span>
      <span style={{'--i': 3}}></span>
    </div>
  
   <div className="glowing">
      <span style={{'--i': 1}}></span>
      <span style={{'--i': 2}}></span>
      <span style={{'--i': 3}}></span>
    </div>
  
  <div className="glowing">
      <span style={{'--i': 1}}></span>
      <span style={{'--i': 2}}></span>
      <span style={{'--i': 3}}></span>
    </div>
   <div className="glowing">
      <span style={{'--i': 1}}></span>
      <span style={{'--i': 2}}></span>
      <span style={{'--i': 3}}></span>
    </div>
<h3>Transparent and Collaborative</h3>
<p className="text-justy">
  In the arsenal of our development company, we wield a versatile array of technological swords, each meticulously chosen to conquer specific realms of the digital battlefield. Our expertise spans across a multitude of domains, including applications, ERP (Enterprise Resource Planning), e-commerce, and more. With the sharp edge of <span class="tech-highlight">PHP</span>, <span class="tech-highlight">Python</span>, <span class="tech-highlight">ReactJS</span>, <span class="tech-highlight">Flutter</span>, <span class="tech-highlight">Angular</span>, <span class="tech-highlight">SQL</span>, <span class="tech-highlight">R</span>, <span class="tech-highlight">Node.js</span>, <span class="tech-highlight">Flask</span>, <span class="tech-highlight">Laravel</span>, <span class="tech-highlight">Next.js</span>, <span class="tech-highlight">MongoDB</span>, <span class="tech-highlight">Oracle</span>, and the strategic mastery of version control through <span class="tech-highlight">Git</span>, we craft solutions that stand as formidable weapons in the dynamic landscape of technology.
</p><br/>
{/*<p className="text-justy">
Our developers, like skilled swordsmiths, forge seamless integrations and powerful functionalities using these cutting-edge technologies. We believe in the potency of diversity, leveraging the strengths of each tool to tailor solutions that align with your unique objectives. Whether it's the swift strokes of ReactJS for responsive user interfaces, the precision strikes of SQL for database management, or the agile maneuvers of Flutter for cross-platform applications, we draw from an extensive armory to ensure victory in every project undertaken.
</p><br/>
<p className="text-justy">
Just as a well-coordinated army requires effective communication and collaboration, our commitment to transparency ensures that you, our esteemed ally, are an integral part of the decision-making process. Regular updates serve as battle reports, detailing progress and victories achieved, while your insights and counsel are sought to strategize and fortify our position. Together, we unveil the battle plan, navigating the grand battlefield of technology with transparency, collaboration, and an unwavering commitment to triumph.
</p>*/}
</div>
</div>



</div>

<div className="col-lg-4">
<div className="sidebar">



<div className="sidebar-widget wow fadeInUp">
<h2 className="widget-title">Our Vision</h2><h4>Conquering New Realms</h4>
<div className="text-widget">
<p className="text-justy">
Our vision unfolds like an ancient map, revealing a world where businesses and individuals thrive through our innovative web applications and transformative solutions. We envision a future where businesses of all sizes unleash their full potential, conquering new realms in the digital landscape.
</p>
</div>
</div>
<div className="sidebar-widget wow fadeInUp">
<h2 className="widget-title">Our Mission</h2><h4>Forging Alliances, Achieving Glory</h4>
<div className="text-widget">
<p className="text-justy">
Our mission is a sacred oath, to build lasting alliances grounded in trust, reliability, and outstanding results. We march forward, propelled by the belief that digital transformation is not a luxury but a necessity. In this modern world, we are the bridge that connects visionary ideas to practical and transformative realities. Our commitment is unwavering - to enable our clients to achieve their goals and emerge victorious in their respective industries.
</p>
</div>
</div>
   <div className="sidebar-widget wow fadeInUp">
                                <h2 className="widget-title">Categories</h2><h4>Armory of Technology</h4>
                                <p>In the arsenal of our technological armory, we brandish weapons that reshape industries:</p>
                                <div className="category-widget">
                                    <ul>
                                        <li><span>Web Application</span><br/><p><i>The Sword of Seamless Connectivity</i></p></li>
                                        <li><span>Mobile Application</span><br/><p><i>The Shield of Accessibility</i></p></li>
                                        <li><span>Website</span><br/><p><i>The Helmet of Digital Presence</i></p></li>
                                        <li><span>E-Commerce</span><br/><p><i>The Bow of Business Expansion</i></p></li>
                                        <li><span>Machine Learning</span><br/><p><i>The Mystic Orb of Intelligent Insights</i></p></li>
                                        <li><span>CRM</span><br/><p><i>The Gauntlet of Customer Relations</i></p></li>
                                        <li><span>ERP</span><br/><p><i>The Enchanted Scroll of Enterprise Management</i></p></li>
                                        <li><span>Payroll</span><br/><p><i>The Ledger of Financial Harmony</i></p></li>
                                    </ul>
                                </div>
                            </div>
</div>

</div>
</div>
</div>
</div>
 

    

   </>
  
  );
};

export default About;
