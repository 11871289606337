// import hero from './hero.png';
import about from './pngegg.png';

const Homeabout = () => {
  return (
 <>
      
       
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="about-img">
                            <img src={about} alt="Image"/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="section-header text-left">
                            <p>Dive into the Chronicles of Valor!</p>
                            <h2>Envision - Innovative - Revolutionize</h2>
                        </div>
                        <div className="about-text">
                            <p className="text-justy">
                                Welcome to Thew Tech, where we're not just developers, but fearless tech-warriors on a mission to revolutionize your digital realm. Our keyboards are our swords, and our screens are the battlegrounds where we craft digital victories.
                            </p>
                            <p className="text-justy">
                                Peer into the future with us as we chart new territories of innovation. Our visionary warriors foresee the digital landscape, paving the way for your business to triumph. We believe in the power of technology to reshape businesses. Join our ranks, and together we'll revolutionize the way you do business – leaving competitors in the dust.
                            </p>
                            <a className="btn" href="/about">Ready to unveil the mysteries? Click to learn more about the warrior's journey and become part of the epic tale at Thew Tech!</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

   </>
  
  );
};

export default Homeabout;
