import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './logo.png';

const Header = () => {
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Attach the scroll-to-top function to the click event of all NavLinks
    const navLinks = document.querySelectorAll('.nav-item.nav-link');
    navLinks.forEach((link) => {
      link.addEventListener('click', handleScrollToTop);
    });

    // Clean up the event listeners when the component unmounts
    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener('click', handleScrollToTop);
      });
    };
  }, []);

  return (
    <>
      <div className="navbar navbar-expand-lg bg-dark navbar-dark">
        <div className="container-fluid">
          <a href="/" className="navbar-brand hiddentext">
            <span>Thew</span>Tech
          </a>
          <a href="/" className="navbar-brand logoanchor">
            <img src={logo} alt="Logo" />
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav ml-auto">
              <NavLink exact to="/" className="nav-item nav-link" activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/about" className="nav-item nav-link" activeClassName="active">
                About
              </NavLink>
              <NavLink to="/services" className="nav-item nav-link" activeClassName="active">
                Services
              </NavLink>
              <NavLink to="/contact" className="nav-item nav-link" activeClassName="active">
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
