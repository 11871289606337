import Layer from '../Layer/Layer.js';
import emailjs from 'emailjs-com';

const Contact = () => {


   // // Configure [EmailJS](https://www.google.com/search?q=EmailJS)
   // emailjs.init('YOUR_USER_ID');

   // // Define your email parameters
   // const emailParams = {
   //   to_email: 'recipient@example.com',
   //   from_name: 'Your Name',
   //   message: 'Hello, this is a test email!',
   // };

   // // Send the email
   // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', emailParams)
   //   .then((response) => {
   //     console.log('Email sent successfully!', response.text);
   //   })
   //   .catch((error) => {
   //     console.error('Error sending email:', error);
   //   });
  return (
 <>
      
            
  <Layer/>  
    <div className="contact">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>Embark on a Digital Crusade</p>
                    <h2>For Any Query</h2>
                </div>
                <div className="row">
<div className="col-lg-12">
<div className="single-content wow fadeInUp">


<p style={{textAlign: 'center'}}>

Join ThewTech's digital crusade, where our code warriors champion your tech quests. Whether launching projects, seeking wisdom, or exploring our services, we're your allies. Let's conquer the digital realm together—your innovation journey begins now.

Have a mission to discuss or a challenge that requires our expertise? Our guild of skilled strategists, developers, and creatives are armed and ready to align with your cause. Cast your signal into the digital ether, and let us join forces to conquer your challenges and claim your place in the annals of digital lore.

</p>
</div>
</div>
</div>

                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 contact-item wow zoomIn" data-wow-delay="0.2s">
                                <i className="fa fa-map-marker-alt"></i>
                                <div className="contact-text">
                                    <h2>Location</h2>
                                    <p>26/19, Bharathidhasan Street, P.N Pudur, Coimbatore</p>
                                </div>
                            </div>
                            <div className="col-md-4 contact-item wow zoomIn" data-wow-delay="0.4s">
                                <i className="fa fa-phone-alt"></i>
                                <div className="contact-text">
                                    <h2>Phone</h2>
                                    <p>+91 9025388166, 
                            +91 7418066768, 
                            +91 9360224118</p>
                                </div>
                            </div>
                            <div className="col-md-4 contact-item wow zoomIn" data-wow-delay="0.6s">
                                <i className="far fa-envelope"></i>
                                <div className="contact-text">
                                    <h2>Email</h2>
                                    <p>info@thewtech.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" novalidate="novalidate">
                                <div className="control-group">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button className="btn" type="submit" id="sendMessageButton">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       

   </>
  
  );
};

export default Contact;
