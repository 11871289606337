import Banner from '../Banner/Banner.js';
import Homeabout from '../About/Homeabout.js';
import Why_choose_us from '../Why_choose_us/Why_choose_us.js';

const Home = () => {
  return (
    <>
<Banner/>
  <Homeabout/>
  <Why_choose_us/>

  </>
  );
};

export default Home;
