import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

import Home from './components/Home/Home.js';
import Layout from './components/Layout/Layout.js';
import About from './components/About/About.js';
import Services from './components/Services/Services.js';
import Contact from './components/Contact/Contact.js';

function App() {
  return (
   
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about"  element={<About/>} />
          <Route path="/Services"  element={<Services/>} />
          <Route path="/Contact"  element={<Contact/>} />
        </Route>
       
      </Routes>
    </BrowserRouter>


  );
}

export default App;
